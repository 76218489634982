.formBody {
    border: 0.5px solid #e5e5e5;
    border-radius: 4px;
    color: #048ba8;

    input,
    label,
    div {
        color: #048ba8;
    }

    input:focus {
        border-bottom: #ffffff solid 1px;
    }

    input:-internal-autofill-selected {
        background-color: transparent;
    }
}

.register {
    background-color: #5385c1;
    // border-radius: 4px;
    button {
        color: #ffffff;
    }
}

.errorBox {
    border: 1px solid #e56b6f;
    background-color: #e56b6f;
    color: #ffffff;
    border-radius: 4px;
    h6 {
        color: #ffffff;
    }
}

.successBox {
    border: 1px solid #80b918;
    background-color: #80b918;
    color: #ffffff;
    border-radius: 4px;
}
