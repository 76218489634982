.selectInput {
    cursor: text;
    display: inline-flex;
    position: relative;
    font-size: 14px;
    box-sizing: border-box;
    align-items: center;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.1876em;
    height: 40px;

    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background: rgba(144, 144, 144, 0.075);
    border-radius: 0;
    border: none;
    border: solid 0.5px #dbdbdb;
    color: inherit;
    display: block;
    outline: 0;
    // padding: 0 1em;
    text-decoration: none;
    width: 100%;

    div.MuiSelect-select.MuiSelect-select {
        //   padding: 0 1em;
        padding-right: none !important;
    }
}

.textareaInput {
    textarea {
        padding: 14px;
    }
}

.textFieldRoot {
    div::before,
    div:hover,
    div::after {
        border-bottom: none;
        transition: none;
        content: none;
    }
}

.autoCompleteRoot {
    .autoCompleteinputRoot {
        padding-right: 0px !important;
        padding-left: 0px;
        padding-top: 0px;
        input {
            padding: 0 14px !important;
        }
    }

    .autoCompleteendAdornment {
        display: none;
    }
}

.autoCompletepaper {
    color: #9a9a9a;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #ffffff;
    .autoCompletelistbox {
        li {
            font-size: 15px;
            span {
                margin-right: 10px;
                font-size: 18px;
            }
        }
    }
}
