.root {
    .inputRoot {
        padding-right: 0px !important;
        padding-left: 0px;
        padding-top: 0px;
        input {
            padding: 0 14px !important;
        }
    }

    .endAdornment {
        display: none;
    }
}

.textFieldRoot {
    div::before,
    div:hover,
    div::after {
        border-bottom: none;
        transition: none;
        content: none;
    }
}

.paper {
    color: #9a9a9a;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #ffffff;
    .listbox {
        li {
            font-size: 15px;
            span {
                margin-right: 10px;
                font-size: 18px;
            }
        }
    }
}
